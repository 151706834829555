@use "../variables" as *;

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, td strong {
    font-weight: 900;
}

h1, h2, h3, h4, h5, h6, li, p {
    font-weight: 400;
    line-height: 1em;
    font-family: $opensans;
    margin: 0 auto;

    sup {
        font-size: 50%;
        top: -0.75em;
    }
}

h1 {
    line-height: 1.2em;
    font-size: 30px;
    @include tablet {
        font-size: 36px;
    }
    @include desktop {
        font-size: 48px;
    }
}

h2 {
    line-height: 1.2em;
    font-size: 24px;
    @include tablet {
        font-size: 30px;
    }
    @include desktop {
        font-size: 36px;
    }
}

h3 {
    font-size: 20px;
    @include tablet {
        font-size: 24px;
    }
    @include desktop {
        font-size: 30px;
    }
}

h4 {
    font-size: 18px;
    @include tablet {
        font-size: 20px;
    }
    @include desktop {
        font-size: 24px;
    }
}

h5 {
    font-size: 16px;
    @include tablet {
        font-size: 18px;
    }
    @include desktop {
        font-size: 20px;
    }
}

h6 {
    font-size: 15px;
    @include tablet {
        font-size: 16px;
    }
    @include desktop {
        font-size: 18px;
    }
}

p, li {
    line-height: 1.6em;
    font-size: 15px;
    @include desktop {
        font-size: 16px;
    }
}

img {
    display: inline-block;
}

.play-button {
    display: inline-block;
    cursor: pointer;
    outline: none;
    transition: opacity .3s;
    background: rgba(0, 0, 0, 0.6);
    border: 3px solid #fff;
    border-radius: 200px;
    line-height: 1em;
    font-size: 29px;
    padding: 18px 22px;
    @include tablet {
        font-size: 35px;
        padding: 22px 27px;
        border-width: 4px;
    }
    @include desktop {
        font-size: 39px;
        padding: 25px 30px;
    }

    &:hover {
        opacity: 0.8;
    }
}

.header {
    padding: 0;
    text-align: center;
    background-color: #000;
    @include tablet {
        text-align: left;
    }

    .container {
        position: relative;
        color: #FFF;
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;
        background: #000 center top/cover no-repeat;
        @include tablet {
            padding: 30px 0 50px;
        }
        @include desktop {
            padding: 50px 0 60px;
        }

    }

    .video-container {
        margin: 0 auto;
        text-align: center;
        max-height: 550px;
        position: relative;

        h1 {
            font-weight: 700;
            font-size: 25px;
            line-height: 1.1em;
            margin: 0 auto;
            text-shadow: 0 0 15px #000;
        }

        .play-button {
            margin: 130px auto 20px;
            @include tablet {
                margin: 200px auto 70px;
            }
            @include desktop {
                margin: 260px auto 150px;
            }
        }
    }

    .text-container {
        padding: 0 15px;

        h1 {
            font-weight: 700;
            font-size: 25px;
            line-height: 1.2em;
            margin: 0 auto 15px;
            text-shadow: 0 0 15px #000;
            @include tablet {
                font-size: 31px;
            }
            @include desktop {
                font-size: 43px;
                margin: 0 auto 25px;
            }
        }

        p {
            font-size: 16px;
            margin: 0 auto 15px;
            font-weight: 600;
            line-height: 1.2em;
            @include tablet {
                font-weight: 400;
                line-height: 1.5em;
                margin: 0 auto 20px;
                text-shadow: 0 0 7px #000;
            }
            @include desktop {
                font-size: 19px;
                margin: 0 auto 35px;
            }
        }

        .join {
            width: 100%;
            max-width: 820px;
        }
    }

    &.stacked {
        .text-container {

            p {
                font-weight: 400;
                margin: 0 auto 15px;
                font-size: 14px;
                @include tablet {
                    font-size: 16px;
                    margin: 0 auto 15px;
                }
                @include desktop {
                    font-size: 19px;
                }

                &.price-info {
                    text-transform: uppercase;
                    margin: 7px auto 0;
                    font-size: 12px;
                    @include tablet {
                        font-size: 14px;
                        margin: 15px auto 0;
                    }
                    @include desktop {
                        font-size: 16px;
                    }

                    s {
                        opacity: 0.7;
                    }
                }
            }
        }

        .course-logo {
            margin: 0 auto 10px;

            h2 {
                font: 400 25px/1em $bebas;
                margin: 0 auto;
                text-transform: uppercase;
                letter-spacing: 1px;
                @include tablet {
                    font-size: 46px;
                    letter-spacing: 4px;
                }
                @include desktop {
                    font-size: 62px;
                    letter-spacing: 8px;
                }
            }
        }
    }
}

.reveal-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    &:after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        content: "\f00d";
        color: #fff;
        z-index: 1;
        opacity: 0.8;
        position: absolute;
        margin: 0;
        line-height: 1em;
        text-align: center;
        display: inline-block;
        outline: none;
        top: 0;
        right: 0;
        font-size: 35px;
        width: 35px;
        @include tablet {
            top: 7px;
            right: 7px;
            font-size: 50px;
            width: 50px;
        }
    }
    .reveal {
        height: inherit;
        min-height: 0;
        max-height: inherit;
        outline: none;
        padding: 0;

        .flex-video {
            margin: 0 auto;
        }
    }
}

.circle-point {
    margin: 30px auto 0;
    @include tablet {
        margin: 40px auto 0;
    }
    @include desktop {
        margin: 50px auto 0;
    }

    .point-icon {
        border: 2px solid $drumeoBlue;
        color: $drumeoBlue;
        border-radius: 50%;
        text-align: center;
        font-size: 35px;
        line-height: 66px;
        height: 70px;
        width: 70px;
        margin: 0 auto 15px;
        @include desktop {
            margin: 0;
            line-height: 96px;
            height: 100px;
            width: 100px;
            font-size: 55px;
            float: left;
        }

        img {
            display: block;
            padding: 20%;
        }

        .fa-music {
            top: -3px;
            position: relative;
        }
    }

    p {
        font: 400 14px/1.5em $opensans;
        margin: 0 auto;
        max-width: 250px;
        @include desktop {
            font-size: 16px;
            width: calc(100% - 100px);
            text-align: left;
            padding-left: 20px;
            float: left;
            max-width: 390px;
        }

        strong {
            font: 700 17px/1em $bebas;
            margin: 0 auto 5px;
            color: $drumeoBlue;
            text-transform: uppercase;
            display: inline-block;
            @include desktop {
                font-size: 23px;
            }
        }
    }
}
