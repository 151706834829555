h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, td strong {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6, li, p {
  margin: 0 auto;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1em;
}

h1 sup, h2 sup, h3 sup, h4 sup, h5 sup, h6 sup, li sup, p sup {
  font-size: 50%;
  top: -.75em;
}

h1 {
  font-size: 30px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  h1 {
    font-size: 36px;
  }
}

@media (width >= 1024px) {
  h1 {
    font-size: 48px;
  }
}

h2 {
  font-size: 24px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  h2 {
    font-size: 30px;
  }
}

@media (width >= 1024px) {
  h2 {
    font-size: 36px;
  }
}

h3 {
  font-size: 20px;
}

@media (width >= 768px) {
  h3 {
    font-size: 24px;
  }
}

@media (width >= 1024px) {
  h3 {
    font-size: 30px;
  }
}

h4 {
  font-size: 18px;
}

@media (width >= 768px) {
  h4 {
    font-size: 20px;
  }
}

@media (width >= 1024px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 16px;
}

@media (width >= 768px) {
  h5 {
    font-size: 18px;
  }
}

@media (width >= 1024px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 15px;
}

@media (width >= 768px) {
  h6 {
    font-size: 16px;
  }
}

@media (width >= 1024px) {
  h6 {
    font-size: 18px;
  }
}

p, li {
  font-size: 15px;
  line-height: 1.6em;
}

@media (width >= 1024px) {
  p, li {
    font-size: 16px;
  }
}

img {
  display: inline-block;
}

.play-button {
  cursor: pointer;
  background: #0009;
  border: 3px solid #fff;
  border-radius: 200px;
  outline: none;
  padding: 18px 22px;
  font-size: 29px;
  line-height: 1em;
  transition: opacity .3s;
  display: inline-block;
}

@media (width >= 768px) {
  .play-button {
    border-width: 4px;
    padding: 22px 27px;
    font-size: 35px;
  }
}

@media (width >= 1024px) {
  .play-button {
    padding: 25px 30px;
    font-size: 39px;
  }
}

.play-button:hover {
  opacity: .8;
}

.header {
  text-align: center;
  background-color: #000;
  padding: 0;
}

@media (width >= 768px) {
  .header {
    text-align: left;
  }
}

.header .container {
  color: #fff;
  text-align: center;
  background: #000 top / cover no-repeat;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}

@media (width >= 768px) {
  .header .container {
    padding: 30px 0 50px;
  }
}

@media (width >= 1024px) {
  .header .container {
    padding: 50px 0 60px;
  }
}

.header .video-container {
  text-align: center;
  max-height: 550px;
  margin: 0 auto;
  position: relative;
}

.header .video-container h1 {
  text-shadow: 0 0 15px #000;
  margin: 0 auto;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.1em;
}

.header .video-container .play-button {
  margin: 130px auto 20px;
}

@media (width >= 768px) {
  .header .video-container .play-button {
    margin: 200px auto 70px;
  }
}

@media (width >= 1024px) {
  .header .video-container .play-button {
    margin: 260px auto 150px;
  }
}

.header .text-container {
  padding: 0 15px;
}

.header .text-container h1 {
  text-shadow: 0 0 15px #000;
  margin: 0 auto 15px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2em;
}

@media (width >= 768px) {
  .header .text-container h1 {
    font-size: 31px;
  }
}

@media (width >= 1024px) {
  .header .text-container h1 {
    margin: 0 auto 25px;
    font-size: 43px;
  }
}

.header .text-container p {
  margin: 0 auto 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2em;
}

@media (width >= 768px) {
  .header .text-container p {
    text-shadow: 0 0 7px #000;
    margin: 0 auto 20px;
    font-weight: 400;
    line-height: 1.5em;
  }
}

@media (width >= 1024px) {
  .header .text-container p {
    margin: 0 auto 35px;
    font-size: 19px;
  }
}

.header .text-container .join {
  width: 100%;
  max-width: 820px;
}

.header.stacked .text-container p {
  margin: 0 auto 15px;
  font-size: 14px;
  font-weight: 400;
}

@media (width >= 768px) {
  .header.stacked .text-container p {
    margin: 0 auto 15px;
    font-size: 16px;
  }
}

@media (width >= 1024px) {
  .header.stacked .text-container p {
    font-size: 19px;
  }
}

.header.stacked .text-container p.price-info {
  text-transform: uppercase;
  margin: 7px auto 0;
  font-size: 12px;
}

@media (width >= 768px) {
  .header.stacked .text-container p.price-info {
    margin: 15px auto 0;
    font-size: 14px;
  }
}

@media (width >= 1024px) {
  .header.stacked .text-container p.price-info {
    font-size: 16px;
  }
}

.header.stacked .text-container p.price-info s {
  opacity: .7;
}

.header.stacked .course-logo {
  margin: 0 auto 10px;
}

.header.stacked .course-logo h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 auto;
  font: 400 25px / 1em Bebas Neue, sans-serif;
}

@media (width >= 768px) {
  .header.stacked .course-logo h2 {
    letter-spacing: 4px;
    font-size: 46px;
  }
}

@media (width >= 1024px) {
  .header.stacked .course-logo h2 {
    letter-spacing: 8px;
    font-size: 62px;
  }
}

.reveal-overlay {
  background-color: #000c;
}

.reveal-overlay:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  content: "";
  color: #fff;
  z-index: 1;
  opacity: .8;
  text-align: center;
  outline: none;
  width: 35px;
  margin: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width >= 768px) {
  .reveal-overlay:after {
    width: 50px;
    font-size: 50px;
    top: 7px;
    right: 7px;
  }
}

.reveal-overlay .reveal {
  height: inherit;
  min-height: 0;
  max-height: inherit;
  outline: none;
  padding: 0;
}

.reveal-overlay .reveal .flex-video {
  margin: 0 auto;
}

.circle-point {
  margin: 30px auto 0;
}

@media (width >= 768px) {
  .circle-point {
    margin: 40px auto 0;
  }
}

@media (width >= 1024px) {
  .circle-point {
    margin: 50px auto 0;
  }
}

.circle-point .point-icon {
  color: #0b76db;
  text-align: center;
  border: 2px solid #0b76db;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto 15px;
  font-size: 35px;
  line-height: 66px;
}

@media (width >= 1024px) {
  .circle-point .point-icon {
    float: left;
    width: 100px;
    height: 100px;
    margin: 0;
    font-size: 55px;
    line-height: 96px;
  }
}

.circle-point .point-icon img {
  padding: 20%;
  display: block;
}

.circle-point .point-icon .fa-music {
  position: relative;
  top: -3px;
}

.circle-point p {
  max-width: 250px;
  margin: 0 auto;
  font: 400 14px / 1.5em Open Sans, sans-serif;
}

@media (width >= 1024px) {
  .circle-point p {
    text-align: left;
    float: left;
    width: calc(100% - 100px);
    max-width: 390px;
    padding-left: 20px;
    font-size: 16px;
  }
}

.circle-point p strong {
  color: #0b76db;
  text-transform: uppercase;
  margin: 0 auto 5px;
  font: 700 17px / 1em Bebas Neue, sans-serif;
  display: inline-block;
}

@media (width >= 1024px) {
  .circle-point p strong {
    font-size: 23px;
  }
}
/*# sourceMappingURL=tripwire.css.map */
